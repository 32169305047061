{
  "applicationForm": {
    "previousStep": "Paso anterior",
    "step": "Paso",
    "submit": "Continuar",
    "yes": "Sí",
    "no": "No",
    "applicationType": {
      "introHeadline": "Comencemos.",
      "intro": "Para iniciar su solicitud, sírvase proveer algunos datos de información adicionales abajo.",
      "pageTitle": "Tipo de solicitud",
      "pageDescription": "Antes de comenzar debemos cerciorarnos qué tipo de cuenta responde a sus necesidades. Por favor seleccione uno de los siguientes.",
      "contactUsLink": "Si no está seguro, <url> contáctenos </url> y le ayudaremos con mucho gusto.",
      "form": {
        "individual": {
          "title": "Personal",
          "description": "Elija esta si va a utilizar la cuenta para sus finanzas personales"
        },
        "soleProprietorship": {
          "title": "Propietario único",
          "description": "Elija esta si es un individuo con plena propiedad de una empresa"
        },
        "business": {
          "title": "Empresa",
          "description": "Elija esta si se presenta en nombre de una corporación, compañía limitada o sociedad"
        }
      }
    },
    "individualInformation": {
      "pageTitle": "Información personal",
      "pageDescription": "Para iniciar su solicitud, sírvase proveer algunos datos de información adicionales abajo.",
      "soleProprietorInformation": {
        "pageTitle": "Información personal",
        "pageDescription": "Para iniciar su solicitud, sírvase proveer algunos datos de información adicionales abajo.",
        "form": {
          "dba": "Haciendo negocios como (Opcional)",
          "ein": "Número de identificación del empleador"
        }
      },
      "form": {
        "firstName": "Nombre",
        "lastName": "Apellido(s)",
        "phone": "Número de teléfono móvil",
        "email": "Email",
        "dateOfBirth": "Fecha de nacimiento",
        "nationality": "Nacionalidad",
        "ssn": "Número de seguridad social",
        "ssnTooltip": "Para comprobar su identidad debemos saber su número de seguridad social completo o su número de identificación fiscal.",
        "passport": "Pasaporte",
        "passportTooltip": "Para comprobar su identidad debemos saber su número de pasaporte completo.",
        "physicalAddress": "Domicilio",
        "physicalAddressTooltip": "No puede usar apartado postal como domicilio",
        "privacyNotice": "Al llenar esta aplicación, usted confirma que está de acuerdo con nuestra <url>Política de privacidad</url>.",
        "submit": "Enviar",
        "continue": "Continuar"
      }
    },
    "individualAdditionalInformation": {
      "pageTitle": "Información adicional individual",
      "pageDescription": "Por favor complete Información adicional.",
      "form": {
        "occupation": "Ocupación",
        "annualIncome": "Ingresos anuales",
        "sourceOfIncome": "Fuente de ingreso",
        "submit": "Enviar",
        "occupationsList": {
          "architectOrEngineer": "Arquitecto o ingeniero",
          "businessAnalystAccountantOrFinancialAdvisor": "Analista de Negocios, Contador o Asesor Financiero",
          "communityAndSocialServicesWorker": "Trabajadora de Servicios Sociales y Comunitarios",
          "constructionMechanicOrMaintenanceWorker": "Trabajador de la construcción, mecánico o mantenimiento",
          "doctor": "Doctor",
          "educator": "Educador",
          "entertainmentSportsArtsOrMedia": "Entretenimiento, Deportes, Artes o Medios",
          "executiveOrManager": "Ejecutivo o gerente",
          "farmerFishermanForester": "Agricultor, pescador, silvicultor",
          "foodServiceWorker": "Trabajador de servicio de comida",
          "gigWorker": "Gig Worker (p. ej., conductor de transporte privado u otro profesional independiente)",
          "hospitalityOfficeOrAdministrativeSupportWorker": "Trabajador de hostelería, oficina o apoyo administrativo",
          "householdManager": "Administrador del hogar",
          "janitorHousekeeperLandscaper": "Conserje, ama de llaves, paisajista",
          "lawyer": "Abogado",
          "manufacturingOrProductionWorker": "Trabajador de fabricación o producción",
          "militaryOrPublicSafety": "Seguridad militar o pública",
          "nurseHealthcareTechnicianOrHealthcareSupport": "Enfermero, Técnico Sanitario o Soporte Sanitario",
          "personalCareOrServiceWorker": "Trabajador de cuidado personal o servicio",
          "pilotDriverOperator": "Piloto, conductor, operador",
          "salesRepresentativeBrokerAgent": "Representante de ventas, Corredor, Agente",
          "scientistOrTechnologist": "Científico o tecnólogo",
          "student": "Alumno"
        },
        "annualIncomesList": {
          "upTo10k": "$0 - $10,000",
          "between10kAnd25k": "$10,000 - $25,000",
          "between25kAnd50k": "$25,000 - $50,000",
          "between50kAnd100k": "$50,000 - $100,000",
          "between100kAnd250k": "$100,000 - $250,000",
          "over250k": "$250,000+"
        },
        "sourcesOfIncomeList": {
          "employmentOrPayrollIncome": "Ingresos laborales o de nómina",
          "partTimeOrContractorIncome": "Ingresos a tiempo parcial o de contratista",
          "inheritancesAndGifts": "Herencias y regalos",
          "personalInvestments": "Inversiones personales",
          "businessOwnershipInterests": "intereses de propiedad empresarial",
          "governmentBenefits": "Beneficios del gobierno"
        }
      }
    },
    "soleProprietorshipBusinessInformation": {
      "pageTitle": "Información de negocios",
      "pageDescription": "Cuéntanos más sobre tu negocio.",
      "form": {
        "businessVertical": "Negocios Verticales",
        "annualRevenue": "Ingresos anuales",
        "numberOfEmployees": "Número de empleados",
        "dba": "Hacer negocios como (opcional)",
        "ein": "Número de identificación de empleado (opcional)",
        "website": "Sitio Web de la empresa (opcional)",
        "hasNoWebsite": "La empresa no tiene página web",
        "submit": "Enviar"
      }
    },
    "businessInformation": {
      "pageTitle": "Información comercial",
      "pageDescription": "Debemos recopilar cierta información básica acerca de su empresa.",
      "form": {
        "businessVertical": "Negocios Verticales",
        "entityType": "Tipo de entidad",
        "industryType": "Tipo de industria",
        "entities": {
          "corporation": "Corporación",
          "publiclyTradedCorporation": "Corporación que cotiza en bolsa",
          "privatelyHeldCorporation": "Corporación de propiedad privada",
          "llc": "Compañía limitada",
          "partnership": "Sociedad",
          "notForProfitOrganization": "Sin ánimo de lucro"
        },
        "industry": {
          "retail": "Retail",
          "wholesale": "Al por mayor",
          "restaurants": "Restaurantes",
          "hospitals": "Hospitales",
          "construction": "Construcción",
          "insurance": "Seguro",
          "unions": "Sindicatos",
          "realEstate": "Inmobiliario",
          "freelanceProfessional": "Independiente profesional",
          "otherProfessionalServices": "Otros servicios profesionales",
          "onlineRetailer": "Comercio digital",
          "otherEducationServices": "Otros servicios educativos"
        },
        "businessName": "Nombre oficial de la empresa",
        "dba": "Haciendo negocios como (Opcional)",
        "ein": "Número de identificación del empleador",
        "phone": "Número de teléfono móvil",
        "stateOfIncorporation": "Estado de incorporación",
        "website": "Sitio Web de la empresa (Opcional)",
        "hasNoWebsite": "La empresa no tiene página web",
        "physicalAddress": "Domicilio",
        "physicalAddressTooltip": "No puede usar apartado postal como domicilio",
        "privacyNotice": "Al llenar esta aplicación, usted confirma que está de acuerdo con nuestra <url>Política de privacidad</url>.",
        "submit": "Continuar"
      }
    },
    "businessAdditionalInformation": {
      "pageTitle": "Información comercial adicional",
      "pageDescription": "Cuéntanos más sobre tu negocio",
      "form": {
        "annualRevenue": "Ingresos anuales",
        "numberOfEmployees": "Número de empleados",
        "cashFlow": "Flujo de fondos",
        "yearOfIncorporation": "Año de incorporacion",
        "countriesOfOperation": "Países de operación",
        "stockSymbol": "Símbolo bursátil",
        "stateOfIncorporation": "Estado de incorporación",
        "hasNonUsEntities": "¿Alguno de los funcionarios o beneficiarios reales de la empresa no es ciudadano Estaod Unidos?",
        "continue": "Continuar",
        "businessVerticalsList": {
          "adultEntertainmentDatingOrEscortServices": "Servicios de entretenimiento para adultos, citas o acompañantes",
          "AdvertisingOrMarketing": "Publicidad/Márketing",
          "agricultureForestryFishingOrHunting": "Agricultura, Silvicultura, Pesca o Caza",
          "artsEntertainmentAndRecreation": "Artes, Entretenimiento y Recreación",
          "businessSupportOrBuildingServices": "Apoyo comercial o servicios de construcción",
          "cannabis": "Canabis",
          "construction": "Construcción",
          "directMarketingOrTelemarketing": "Marketing Directo/Telemarketing",
          "educationalServices": "Servicios educativos",
          "financialServicesCryptocurrency": "Servicios financieros - Criptomoneda",
          "financialServicesDebitCollectionOrConsolidation": "Servicios financieros: cobro o consolidación de deudas",
          "financialServicesMoneyServicesBusinessOrCurrencyExchange": "Servicios Financieros - Negocios de Servicios Monetarios o Cambio de Divisas",
          "financialServicesOther": "Servicios Financieros - Otros",
          "financialServicesPaydayLending": "Servicios financieros - Préstamos de día de pago",
          "gamingOrGambling": "Juegos/Apuestas",
          "healthCareAndSocialAssistance": "Asistencia Sanitaria y Social",
          "hospitalityAccommodationOrFoodServices": "Servicios de Hostelería, Alojamiento o Alimentación",
          "legalAccountingConsultingOrComputerProgramming": "Legal, Contabilidad, Consultoría o Programación Informática",
          "manufacturing": "Fabricación",
          "mining": "Minería",
          "nutraceuticals": "Nutracéuticos",
          "personalCareServices": "Servicios de cuidado personal",
          "publicAdministration": "Administración Pública",
          "realEstate": "Inmobiliaria",
          "religiousCivicAndSocialOrganizations": "Organizaciones Religiosas, Cívicas y Sociales",
          "repairAndMaintenance": "Reparación y mantenimiento",
          "retailTrade": "Comercio al por menor",
          "technologyMediaOrTelecom": "Tecnología, medios o telecomunicaciones",
          "transportationOrWarehousing": "Transporte o Almacenamiento",
          "utilities": "Utilidades",
          "wholesaleTrade": "Comercio al por mayor"
        },
        "soleProprietorshipAnnualRevenuesList": {
          "upTo50k": "$0 - $50,000",
          "between50kAnd100k": "$50,000 - $100,000",
          "between100kAnd200k": "$100,000 - $200,000",
          "between200kAnd500k": "$200,000 - $500,000",
          "over500k": "$500,000"
        },
        "businessAnnualRevenuesList": {
          "upTo250k": "$0 - $250,000",
          "between250kAnd500k": "$250,000 - $500,000",
          "between500kAnd1m": "$500,000 - $1,000,000",
          "between1mAnd5m": "$1,000,000 - $5,000,000",
          "over5m": "$5,000,000+"
        },
        "soleProprietorshipNumberOfEmployeesList": {
          "one": "1 (solo yo)",
          "between2And5": "2 - 5",
          "between5And10": "5 - 10",
          "over10": "10+"
        },
        "businessNumberOfEmployeesList": {
          "upTo10": "Hasta 10",
          "between10And50": "10 - 50",
          "between50and100": "50 - 100",
          "between100and500": "100 - 500",
          "over500": "500+"
        },
        "cashFlowList": {
          "unpredictable": "Impredecible",
          "predictable": "Predecible"
        }
      }
    },
    "officerInformation": {
      "pageTitle": "Información del funcionario",
      "pageDescription": "Seguidamente, necesitaremos información acerca de un alto empleado de la empresa",
      "form": {
        "firstName": "Nombre",
        "lastName": "Apellido(s)",
        "title": "Cargo",
        "titlesList": {
          "ceo": "Gerente general",
          "coo": "Gerente",
          "cfo": "Jefe de dirección financiera",
          "president": "Presidente",
          "benefitsAdministrationOfficer": "Beneficios",
          "cio": "Oficial en jefe de información",
          "vp": "Vicepresidente",
          "avp": "Vicepresidente adjunto",
          "treasurer": "Tesorero",
          "secretary": "Secretario",
          "controller": "Contralor",
          "manager": "Director",
          "partner": "Socio",
          "member": "Miembro"
        },
        "phone": "Número de teléfono móvil",
        "email": "Email",
        "dateOfBirth": "Fecha de nacimiento",
        "nationality": "Nacionalidad",
        "ssn": "Número de seguridad social",
        "ssnTooltip": "Para comprobar la identidad, necesitaremos saber el número completo de seguridad social del funcionario o su número personal de identificación fiscal.",
        "passport": "Pasaporte",
        "primaryPointOfContact": "Este funcionario también es el primer punto de contacto",
        "businessContact": "Contacto comercial",
        "submit": "Continuar",
        "officerIsBeneficialOwner": "Este oficial también es un beneficiario final",
        "officerIsBeneficialOwnerTooltip": "Un beneficiario oficial es cualquier persona que posee más del 25% de la empresa"
      }
    },
    "beneficialOwnersInformation": {
      "pageTitle": "Información del beneficiario real",
      "pageDescription": "Deberá llenar la siguiente información referente a todo propietario que tenga 25% o más de su organización ",
      "form": {
        "numberOfBeneficialOwners": "¿Cuántos propietarios tienen 25% o más?",
        "numberOfBeneficialOwnersList": {
          "zero": "Cero",
          "one": "Uno",
          "two": "Dos",
          "three": "Tres",
          "four": "Cuatro"
        },
        "owner": "Propietario",
        "firstName": "Nombre",
        "lastName": "Apellido(s)",
        "phone": "Número de teléfono móvil",
        "email": "Email",
        "dateOfBirth": "Fecha de nacimiento",
        "nationality": "Nacionalidad",
        "ssn": "Número de seguro social",
        "ssnTooltip": "Para comprobar su identidad debemos saber su número de seguridad social completo o su número de identificación fiscal.",
        "passport": "Pasaporte",
        "percentage": "Porcentaje",
        "beneficialOwnersAgreement": "Al proporcionar la información anterior, certifico de esta manera que a mi leal saber y entender, la información proporcionada está completa y es correcta.",
        "submit": "Enviar"
      }
    },
    "phoneVerification": {
      "pageTitle": "Verificación del Teléfono",
      "pageDescription": "Le hemos enviado por SMS el código de verificación de: {{phoneNumber}}",
      "form": {
        "submit": "Verificar",
        "resend": "Reenviar"
      },
      "tryToResendMessage": "Por favor, intente reenviar o vuelva a intentar más tarde "
    },
    "applicationCreated": {
      "pageTitle": "Solicitud creada"
    },
    "addressForm": {
      "street": "Calle",
      "apartment": "Apartamento, unidad, suite o # de piso (Opcional)",
      "city": "Ciudad",
      "country": "País",
      "state": "Estado",
      "zip": "Código postal"
    },
    "legalAgreement": {
      "privacyPolicy": "Política de privacidad",
      "consentToElectronicDisclosures": "Consentimiento a la divulgación electrónica",
      "depositTermsConditions": "Términos y condiciones del depósito",
      "clientTermsOfService": "Términos de servicio al cliente",
      "cardholderTermsConditions": "Términos y condiciones del titular de la tarjeta",
      "cashAdvanceTermsConditions": "Términos y condiciones del anticipo en efectivo al titular de la tarjeta",
      "agreement": "Estoy de acuerdo con",
      "bankNotice": "{{clientName}} es una compañía financiera tecnológica y no es un banco. Los servicios bancarios suministrados por {{bankName}}; Miembro de FDIC (Corporación Federal de Seguro de Depósitos).",
      "visaNotice": "La tarjeta {{clientName}} Visa® Debit es expedida por el banco patrocinador, como se indica en el acuerdo del titular de la tarjeta de débito, de acuerdo con una licencia de Visa® U.S.A. Inc., y puede utilizarse en todos los lugares donde se aceptan tarjetas Visa.",
      "debitCardDisclosure": "Acuerdo de la tarjeta de débito"
    },
    "applicationDocuments": {
      "documentTypes": {
        "idDocument": {
          "title": "ID",
          "description": "Por favor, proporcione una copia de su documento de identidad con fotografía emitido por el gobierno y que no haya caducado, como el permiso de conducir o el documento de identidad estatal."
        },
        "passport": {
          "shortKey": "Pasaporte",
          "title": "Pasaporte ",
          "description": "Por favor, proporcione una copia de su pasaporte que no haya caducado."
        },
        "addressVerification": {
          "shortKey": "Domicilio",
          "title": "Verificación del domicilio",
          "description": "Por favor, proporcione un documento para verificar su domicilio. El documento puede ser una factura de servicios públicos, un extracto bancario, un contrato de alquiler o un recibo de sueldo actual."
        },
        "ssn": {
          "shortKey": "Número de seguridad social",
          "title": "Tarjeta de seguridad social",
          "description": "Por favor, proporcione un documento para verificar su número de seguridad social (SSN) como la declaración de la renta o la tarjeta de la seguridad social."
        },
        "socialSecurityCard": {
          "shortKey": "Número de seguridad social",
          "title": "Tarjeta de seguridad social",
          "description": "Por favor, proporcione un documento para verificar su número de seguridad social (SSN) como la declaración de la renta o la tarjeta de la seguridad social."
        },
        "certificateOfIncorporation": {
          "title": "Certificado de Constitución",
          "description": "Para una corporación: Por favor, proporcione una copia certificada de los Artículos de Constitución o del Certificado de Constitución. En el caso de una sociedad: Proporcione una copia del acuerdo de asociación. En el caso de las sociedades de responsabilidad limitada: Proporcione una copia certificada de los artículos de la organización."
        },
        "employerIdentificationNumberConfirmation": {
          "shortKey": "EIN",
          "title": "Confirmación del número de identificación del empleador",
          "description": "Proporcione un documento que verifique su EIN (número de identificación del empleador) federal, como una declaración de impuestos de la empresa, la carta 147c del IRS o el formulario CP 575 del IRS."
        }
      },
      "form": {
        "required": "Requerido para {{clientName}}",
        "uploadDocument": "Cargar el documento:",
        "uploadFront": "Cargar el anverso del documento:",
        "uploadBack": "Cargar el reverso del documento",
        "uploadButton": "Cargar el archivo",
        "cameraButton": "Abrir la cámara"
      },
      "documentStatuses": {
        "approved": "Documento aprobado",
        "pendingReview": "Pendiente de revisión",
        "invalid": "Documento no válido"
      },
      "formStatuses": {
        "approved": {
          "pageTitle": "Solicitud aprobada",
          "pageDescription": "La solicitud ha sido aprobada"
        },
        "denied": {
          "pageTitle": "Solicitud denegada",
          "pageDescription": "La solicitud ha sido denegada"
        },
        "canceled": {
          "pageTitle": "Solicitud cancelada",
          "pageDescription": "La solicitud ha sido cancelada"
        },
        "pending": {
          "pageTitle": "Solicitud de procesamiento",
          "pageDescription": "Su solicitud está siendo procesada. La evaluación puede tardar hasta un minuto, por favor no cierre la ventana mientras la solicitud está en curso. Gracias por su paciencia",
          "delayMessage": "La evaluación de su solicitud tarda más de lo previsto y puede requerir un procesamiento fuera de línea. Puede volver a intentarlo, o hacer clic en 'continuar' para salir de la aplicación y volver en otro momento.",
          "loading": "Espere por favot ",
          "reTry": "Vuelva a intentarlo",
          "contactSupport": "Si el problema persiste, contacte <url> asistencia </url>"
        },
        "pendingReview": {
          "pageTitle": "Pendiente de revisión",
          "pageDescription": "La solicitud ha sido presentada y está siendo evaluada"
        },
        "awaitingDocuments": {
          "pageTitle": "Cargar documentos adicionales",
          "pageDescription": "Para verificar su información y tomar una decisión sobre su solicitud, tendría que proporcionar documentación adicional."
        }
      }
    },
    "dataCollection": {
      "pageTitle": "Se requiere información adicional para continuar.",
      "pageDescription": "Recientes cambios en nuestros procedimientos bancarios requieren proporcionar información adicional.",
      "pageDescriptionContactUs": "Para obtener más detalles, puede comunicarse con nuestro soporte en {email}",
      "warningText": "Si no se proporciona la información, se cerrará la cuenta",
      "submit": "Enviar",
      "completedTitle": "Gracias",
      "completedDescription": "Los datos se recopilaron correctamente"
    },
    "validationErrorMessages": {
      "error": "Ha ocurrido un error",
      "firstName": {
        "required": "Por favor escriba su nombre"
      },
      "lastName": {
        "required": "Por favor escriba su(s) apellido(s)"
      },
      "phone": {
        "required": "Por favor escriba el número de su teléfono móvil",
        "invalid": "El número telefónico que ha escrito no es válido"
      },
      "email": {
        "required": "Por favor escriba su dirección de email",
        "invalid": "La dirección de email que ha escrito no es válida"
      },
      "ssn": {
        "required": "Por favor, introduzca su número de seguridad social completo de 9 dígitos",
        "invalid": "El número de seguridad social que ha escrito no es válido (el formato es xxx-xx-xxxx)"
      },
      "nationality": {
        "required": "Por favor elija nacionalidad"
      },
      "passport": {
        "required": "Por favor ingrese su pasaporte completo"
      },
      "street": {
        "required": "Por favor escriba el nombre de la calle"
      },
      "city": {
        "required": "Por favor escriba su ciudad de residencia"
      },
      "state": {
        "required": "Por favor seleccione el estado de residencia"
      },
      "postalCode": {
        "required": "Por favor escriba su código postal (debe tener 5 o 9 dígitos)",
        "usInvalid": "El código postal que ha provisto no es válido (los códigos postales de USA deben contener 5 o 9 dígitos)",
        "caInvalid": "El código postal que ha provisto no es válido (los códigos postales de CA son en formato A1A 1A1, donde A es una letra y 1 es un dígito)"
      },
      "country": {
        "required": "Por favor seleccione el país de residencia"
      },
      "dateOfBirth": {
        "required": "Por favor escriba la fecha de nacimiento",
        "max": "Debes tener por lo menos 18 años para enviar una solicitud",
        "invalid": "La fecha de nacimiento que ha escrito no es válida"
      },
      "businessName": {
        "required": "Por favor escriba el nombre oficial de su empresa"
      },
      "stateOfIncorporation": {
        "required": "Por favor seleccione el estado de constitución de la empresa"
      },
      "ein": {
        "required": "Por favor escriba el número de identificación del empleador de 9 dígitos ",
        "invalid": "El número de identificación del empleador que ha escrito no es válido (debe ser de 9 dígitos)"
      },
      "website": {
        "invalid": "La URL del sitio Web que ha proporcionado no es válida "
      },
      "hasNoWebsite": {
        "required": "Es obligatorio marcar este campo si el negocio no tiene un página web web"
      },
      "agreement": {
        "required": "Es necesario marcar este campo"
      },
      "businessEntityType": {
        "required": "Este campo es necesario",
        "invalid": "Valor no válido"
      },
      "industry": {
        "required": "Este campo es necesario",
        "invalid": "Valor no válido"
      },
      "beneficialOwnershipPercentage": {
        "required": "Por favor escriba la participación del beneficiario oficial (%) en el negocio",
        "range": "El rango debe estar entre 25 y 100"
      },
      "phoneVerificationNumber": {
        "required": "Por favor escriba el código de verificación",
        "invalid": "Por favor escriba un dígito"
      },
      "occupation": {
        "required": "Este campo es necesario",
        "invalid": "Valor no válido"
      },
      "annualIncome": {
        "required": "Este campo es necesario",
        "invalid": "Valor no válido"
      },
      "sourceOfIncome": {
        "required": "Este campo es necesario",
        "invalid": "Valor no válido"
      },
      "businessVertical": {
        "required": "Este campo es necesario",
        "invalid": "Valor no válido"
      },
      "annualRevenue": {
        "required": "Este campo es necesario",
        "invalid": "Valor no válido"
      },
      "numberOfEmployees": {
        "required": "Este campo es necesario",
        "invalid": "Valor no válido"
      },
      "cashFlow": {
        "required": "Este campo es necesario"
      },
      "yearOfIncorporation": {
        "required": "Este campo es necesario",
        "invalid": "Por favor, introduzca un año válido"
      },
      "hasNonUsEntities": {
        "required": "Este campo es necesario"
      },
      "countriesOfOperation": {
        "required": "Este campo es necesario"
      },
      "stockSymbol": {
        "required": "Este campo es necesario"
      }
    }
  }
}